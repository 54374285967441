// Load Styles
import 'swiper/css/bundle';
import '../scss/main.scss';

// Load Bootstrap init
import {initBootstrap, showTab} from "./bootstrap";
import Stepper from 'bs-stepper'
import {timeList} from "./timelist";
import Swiper from 'swiper/bundle';

// Loading bootstrap with optional features
initBootstrap({
  tooltip: true, popover: true, toasts: true,
});

// Your app code
document.addEventListener('DOMContentLoaded', function () {
  let stepper = new Stepper(document.querySelector('.bs-stepper'));

  let lastStepButton = document.getElementById('last-step');
  lastStepButton.addEventListener("click", () => {
    document.getElementsByClassName('bs-stepper-header')[0].classList.add('d-none');
    document.getElementsByClassName('bs-stepper-content')[0].classList.remove('mb-auto');
  });

  let nextButtons = document.getElementsByClassName("next-step");

  for (const nextButton of nextButtons) {
    nextButton.addEventListener("click", () => {
      stepper.next();
    });
  }

  let prevButtons = document.getElementsByClassName("prev-step");
  for (const prevButton of prevButtons) {
    prevButton.addEventListener("click", () => {
      stepper.previous();
      console.log(stepper._currentIndex);
    });
  }

  let datepickerNavigation = document.getElementsByClassName("datepickerNavigation");
  for (const datepickerNavigationElement of datepickerNavigation) {
    if (datepickerNavigationElement.classList.contains('datepickerNext')) {
      datepickerNavigationElement.addEventListener("click", () => {
        showTab(document.querySelector('#datepickerTab li button.active').closest('li').nextElementSibling.querySelector('button'));
      });
    }
    if (datepickerNavigationElement.classList.contains('datepickerPrev')) {
      datepickerNavigationElement.addEventListener("click", () => {
        showTab(document.querySelector('#datepickerTab li button.active').closest('li').previousElementSibling.querySelector('button'));
      });
    }
  }

  timeList(document.querySelectorAll("#today-tab-pane input[name='job_reminder[time]']"));
  timeList(document.querySelectorAll("#tomorrow-tab-pane input[name='job_reminder[time]']"));
  timeList(document.querySelectorAll("#other-tab-pane input[name='job_reminder[time]']"));


  const container = document.getElementsByClassName('swiper-wrapper')[0];
  let jobs = Array.from(document.querySelectorAll('.swiper-wrapper > .card'));
  const spacerContainer = document.createElement("div");
  spacerContainer.classList.add('card', 'rounded-0', 'border-0');
  const spacer = document.createElement("div");
  spacer.classList.add('card-body', 'no-hover', 'p-0');
  spacerContainer.appendChild(spacer);


  const navigation = jobs.pop();
  container.innerHTML = '';
  let itemsInSlide = 3;

  if (window.innerWidth >= 1200) {
    itemsInSlide = 11;
    jobs.splice(0, 0, spacerContainer);
    jobs.splice(0, 0, spacerContainer.cloneNode(true));
    jobs.splice(4, 0, spacerContainer.cloneNode(true));
  } else if (window.innerWidth >= 992) {
    itemsInSlide = 8;
    jobs.splice(0, 0, spacerContainer);
    jobs.splice(0, 0, spacerContainer.cloneNode(true));
    jobs.splice(3, 0, spacerContainer.cloneNode(true));
  } else if (window.innerWidth >= 768) {
    itemsInSlide = 5;
  }

  for (let i = 0; i < jobs.length; i += itemsInSlide) {
    let newDiv = document.createElement("div");
    //d-flex flex-wrap align-items-start swiper-slide
    newDiv.classList.add('d-flex', 'flex-wrap', 'align-items-start', 'align-content-start', 'swiper-slide');
    let chunk = jobs.slice(i, i + itemsInSlide);
    let nextbutton = navigation.cloneNode(true);
    newDiv.append(...chunk, nextbutton);
    container.appendChild(newDiv);
    newDiv = null;
    chunk = null;
    nextbutton = null;
  }

  let swiper = new Swiper(".jobs-swiper", {
    autoHeight: true,
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    loop: true,
    navigation: {
      nextEl: ".next-slider",
    }
  });

  swiper.on('slideChange', () => {
    let anchor = document.querySelector('.section-joblist');
    anchor.scrollIntoView();
  });

  let notifications = Array.from(document.querySelectorAll('.notify'));
  for (const notification of notifications) {
    let img = notification.querySelector('img');
    let strong = notification.querySelector('strong');
    img.onmouseover = function () {
      this.parentElement.classList.add('hover');
      strong.innerHTML = strong.dataset.bapHover;
    }

    notification.onmouseleave = function() {
      this.classList.remove('hover')
      strong.innerHTML = strong.dataset.bapOriginal;
    }
  }
});
